import { render, staticRenderFns } from "./B2Detail.vue?vue&type=template&id=106e9993&scoped=true"
import script from "./B2Detail.vue?vue&type=script&lang=js"
export * from "./B2Detail.vue?vue&type=script&lang=js"
import style0 from "./B2Detail.vue?vue&type=style&index=0&id=106e9993&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106e9993",
  null
  
)

export default component.exports